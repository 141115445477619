<template>
  <b-container fluid>
    <bo-page-title />

    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row align-h="end">
          <b-col md>
            <b-row class="gutter-1">
              <b-col md="6" lg="4" xl="3">
                <v-select @input="doFilter()" v-model="filter.status" :options="mrStatus" :reduce="data => data.value" />
              </b-col>
              <b-col md="6" lg="4" xl="3"></b-col>
            </b-row>
          </b-col>
          <b-col md="auto">
            <SearchInput :value.sync="filter.search" @search="doFilter" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
         <b-table
          :fields="tableFields"
          :items="dataList || []"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          striped
          show-empty
        >
          <template #empty>
            <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
            </div>
          </template>
          <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
        <template #cell(num)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tta_finish_by)="data">
          {{data.value || '-'}}
        </template>
        <template #cell(tta_finish_date)="data">
          <template v-if="data.value">
            {{data.value | moment('LLL')}}
          </template>
          <p v-else>-</p>
        </template>
        <template #cell(tta_status)="data">
          <b-badge :variant="status(data.value).variant">{{ status(data.value).label }}</b-badge>
        </template>
        <template #cell(action)="data">
          <router-link class="btn btn-icon btn-outline-success" variant="outline-success" v-b-tooltip.hover="'Check Task'" :to="{ name: $route.name, params: { pageSlug: data.item.tta_id } }"><i class="fas fa-eye"></i></router-link>
        </template>
        </b-table>
      </b-card-body>
      <b-card-footer>
        <template v-if="(dataList||[]).length&&data.total>10">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <label for="perPage" class="mb-0 mr-2">Show</label>
                <b-form-select
                  size="sm"
                  id="perPage"
                  class="w-auto mx-50"
                  v-model="perPage"
                  :options="Config.dataShownOptions"
                />
              <label for="perPage" class="mb-0 ml-2">items per page</label>
            </div>
            <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
            />
          </div>
        </template>
      </b-card-footer>
    </b-card>
    <b-card no-body v-else>
      <Form :row.sync="row" :mrValidation="mrValidation" />
    </b-card>
  </b-container>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
  import Form from './Form.vue'
  
  export default {
		extends: GlobalVue,
    components: {
      Form
    },
    data() {
      return {
        idKey: 'tte_id',
        statusKey: 'tte_status',
        mrValidation: {},
        tableFields: [
          { key: 'num', label: '#' },
          { key: 'mti_title', label: 'Task' },
          { key: 'tta_finish_by', label: 'Finish By' },
          { key: 'tta_finish_date', label: 'Finish Date' },
          { key: 'tta_status', label: 'Status' },
          { key: 'action', label: 'Action' },
        ],
        mrStatus: [],
      }
    },
    mounted(){
      this.setFilter()
      this.apiGet()
    },
    methods: {
      status(status){
        const listStatus = {
          'failed': {
            variant: 'danger',
            label: 'Failed'
          },
          'finish': {
            variant: 'success',
            label: 'Finish'
          }
        }

        return listStatus[status] || {variant: '', label: ''}
      },
      setFilter(){
        this.filter = {
          status: this.$route.query.status || 'all'
        }
      },
      submitReject(){
        this.$refs.VFormReject.validate().then(success => {
          if(!success) return
          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })
          return this.doSubmit('/do/' + this.modulePage, Object.assign({ type: 'reject'}, {...this.dataModal}), (type, resp) => {
            if(type == 'success'){
              this.$swal({
              title: resp.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(result => {
              if(result.value){
                this.$bvModal.hide('rejectModal')
                this.$set(this, 'dataModal', {})
                this.apiGet()
              }
            })
            }
          }, "POST", "VFormReject")
        })
      }
    },
    watch:{
      $route(){
        this.setFilter()
        this.apiGet()
      },
    }
  }
</script>