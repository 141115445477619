<template>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">Detail {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
           <b-col md=3>
            <label>Status</label>
            <p>
              <b-badge v-if="row.tta_status == 'finish'" variant="success">Finish</b-badge>
              <b-badge v-else-if="row.tta_status == 'failed'" variant="danger">Failed</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md=3>
            <label>Task Item</label>
            <p class="task-item">{{ row.mti_title }}</p>
            <p><strong>{{ row.tta_task_category_name }}</strong></p>
          </b-col>
          <b-col md=3>
            <label>PIC</label>
            <p class="task-item">{{ row.tta_pic_name }}</p>
            <p> <small>Finish By: </small><strong>{{ row.tta_finish_by || '-' }}</strong></p>
          </b-col>
          <b-col md=4>
            <label>Date</label>
            <p class="task-item"> Date: <strong>{{ row.tta_start_date }}</strong> s.d. <strong>{{ row.tta_end_date }}</strong></p>
            <p class="task-item">
              <small>Finish Date: </small>
              <span class="text-success" v-if="row.tta_finish_date">{{ row.tta_finish_date | moment('LLL') }}</span>
              <span v-else>-</span>
            </p>
          </b-col>
          <b-col md=3>
            <label>Note</label>
            <p>{{ row.tta_note || '-' }}</p>
          </b-col>
          <b-col md=3>
            <label>Photo</label>
            <silent-box v-if="row.image" :image="{ src: row.image, srcSet: row.image,}">
              <template v-slot:silentbox-item>
                <p>See Photo</p>
              </template>
            </silent-box>
            <p v-else>-</p>
          </b-col>
          <b-col md=3 v-if="row.tta_status == 'failed'">
            <label>Failed Reason</label>
            <p>
              {{row.tta_failed_reason}}
            </p>
          </b-col>
        </b-row>
        <b-row v-if="row.evaluations && row.evaluations.length">
          <b-col md=12>
            <b-card>
              <b-card-header>
                <h5 class="card-title">Evaluations</h5>
              </b-card-header>
                <b-table :items="row.evaluations" :fields="evaluationFields" primary-key="tte_id" responsive striped show-empty>
                  <template #cell(num)="v">
                    {{v.index+1}}
                  </template>
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle mr-3 text-info"></b-spinner>
                      <strong class="text-info">Loading...</strong>
                    </div>
                  </template>
                  <template #cell(tte_approve_by_name)="data">
                    {{data.value || '-'}}
                  </template>
                  <template #cell(tte_status)="data">
                    <b-badge v-if="data.value == 'pending'" variant="warning">Pending</b-badge>
                    <b-badge v-else-if="data.value == 'approve'" variant="success">Approve</b-badge>
                    <b-badge v-else-if="data.value == 'reject'" variant="danger">Reject</b-badge>
                  </template>
                </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">{{$parent.isAdd ? 'Cancel' : 'Back'}}</button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
</template>
<style scoped>
.task-item {
  margin: 0;
}
</style>
<script>

 export default {
    props: {
      row: Object,
      mrValidation: Object,
    },
    data(){
      return {
        dataModal: {},
        evaluationFields: [
          { key: 'num', label: '#' },
          { key: 'tte_date', label: 'Date' },
          { key: 'tte_approve_by_name', label: 'Approve / Reject By' },
          { key: 'tte_note', label: 'Note' },
          { key: 'tte_status', label: 'Status' },
        ]
      }
    },
    methods: {
    }
 }
</script>
